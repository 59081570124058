'use client'
import { Button } from '@/shared/components/Button'
import { logger } from '@/shared/utils/logger'
import { useEffect } from 'react'

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) {
	useEffect(() => {
		// Log the error to an error reporting service
		logger('error', 'Global error', { error })
	}, [error])

	return (
		<div className="flex flex-col items-center justify-center h-screen">
			<h2>Something went wrong!</h2>
			<Button
				className="mt-4"
				onClick={
					// Attempt to recover by trying to re-render the segment
					() => reset()
				}
			>
				Try again
			</Button>
		</div>
	)
}
