import { cn } from '../utils/cn'

function isTailwindClass(token: string) {
	return /^[a-z]+-[a-z]+-\d+$/.test(token)
}

export type SpinnerProps = {
	size?: number
	borderWidth?: number
	color?: string
	className?: string
}

/**
 * A customizable spinner component for loading states.
 *
 * @component
 * @example
 * // Default usage
 * <Spinner />
 *
 * // Custom size and border width
 * <Spinner size={48} borderWidth={6} />
 *
 * @param {Object} props - The component props
 * @param {number} [props.size=32] - The size of the spinner in pixels
 * @param {number} [props.borderWidth=4] - The width of the spinner's border in pixels
 * @param {string} [props.color='primary-500'] - The color of the spinner
 * @returns {JSX.Element} A spinning circular loader
 */
export const Spinner = ({
	size = 32,
	borderWidth = 4,
	color = 'text-primary-500',
	className,
}: SpinnerProps) => {
	const tailwindClass = color
	const customStyle = !isTailwindClass(color) ? { borderColor: color } : {}

	return (
		<div
			className={cn(`animate-spin rounded-full`, tailwindClass, className)}
			style={{
				width: `${size}px`,
				height: `${size}px`,
				...customStyle,
				borderWidth: `${borderWidth}px`,
				borderTopColor: 'transparent',
			}}
		></div>
	)
}
